export default [
  {
    icon: () => import("/assets/icons/group.svg?inline"),
    text: "sledovateľov online",
    color: "primary",
    backgroundColor: "blue-light",
    image: "/polygon1.png",
    apiKey: "onlineFollowers",
  },
  {
    icon: () => import("/assets/icons/teacher.svg?inline"),
    text: "registrovaných učiteľov",
    color: "orange",
    backgroundColor: "orange-light",
    image: "/polygon2.png",
    apiKey: "registeredUsers",
  },
  {
    icon: () => import("/assets/icons/video.svg?inline"),
    text: "video kurzov",
    color: "secondary",
    backgroundColor: "red-light",
    image: "/polygon3.png",
    apiKey: "videoCourses",
  },
];
